import React, { Component } from 'react'

import { DateTime } from 'luxon'

import 'react-day-picker/lib/style.css';

import styled from 'styled-components'

export const Button = styled.button`
  text-transform: uppercase;
  padding: .5rem 2rem .5rem;
  border: 2px solid #fac129;
  background-color: ${props => props.selected ? "#fac129" : "white"};
  color: #888;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
  margin: .5rem;
`

const SlotButton = styled(Button)`
  text-transform: none;
`


class BookingWidget extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedSlot: undefined,
    }
  }

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    this.setState({
      selectedSlot: undefined,
    })
    this.props.updateValue && this.props.updateValue('')
  }

  handleButtonClicked = (param) => (e) => {
    this.setState({
      selectedSlot: param
    })
    this.props.updateValue && this.props.updateValue(param.id)
  }


  render() {

    const uiDateTime = (isoDateTime) => {
      const dt = DateTime.fromISO(isoDateTime)
      return dt.toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
    }

    return (
      <div>
        {this.props.slots.length > 0 ?
            this.props.slots.sort((a, b) => a.datetime > b.datetime)
                .map(s =>
                  <SlotButton
                    key={`bookingwidget_${s.id}`}
                    type='button'
                    onClick={this.handleButtonClicked(s)}
                    selected={this.state.selectedSlot === s}
                  >
                    <div>
                      {uiDateTime(s.datetime)}
                    </div>
                  </SlotButton>
                )
            :
            <p>No slot available currently. Contact hello@duraj.space for enquiries.</p>
          }
      </div>
    )
  }
}

export default BookingWidget