import React, { Component } from "react"
import { DateTime } from 'luxon'
import axios from 'axios'

import { Formik } from 'formik'
import classNames from "classnames"
import * as Yup from 'yup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { loadStripe } from '@stripe/stripe-js'

import { Flex, Box } from '@rebass/grid'

import { Input, Textarea, Label, Button } from './form'


import BookingWidget from "./BookingWidget";
import CurrencyButtonGroup from "./CurrencyButtonGroup"


const stage = 'dev' // will be prod...
//const stage = 'prod' // will be dev...

const session_api_url = {
  prod: 'http://localhost:1337s/sessions',
  dev: 'https://api-staging.duraj.space/sessions',
}[stage]

// prod as in new table, will switch to dev later...
const api_url = {
  prod: 'https://k2thgbt27k.execute-api.eu-west-1.amazonaws.com/api/',
  dev: 'https://dtkm39ebo3.execute-api.eu-west-1.amazonaws.com/api',
}[stage]

const stripe_key = {
  prod: 'pk_live_28Ha1xM1P5XqDPc4XLmJRLcJ',
  dev: 'pk_test_tm5K2G6F82u8VbbTbPxsFqFD',
}[stage]

// Input feedback
const InputFeedback = ({ error }) =>
  error ? <div className={classNames("input-feedback")}>{error}</div> : null;

const label = {
  en: {
    name: 'Name*',
    email: 'Email*',
    phone: 'Phone',
    skype: 'Skype',
    message: 'Message',
    available_slots: 'Available slots',
    pay: 'Pay',
    book: 'Book',
  },
  ru: {
    name: 'Имя*',
    email: 'Эл. адрес*',
    phone: 'Телефон',
    skype: 'Скайп',
    message: 'Сообщение',
    available_slots: 'Время консультации',
    pay: 'Оплатить',
    book: 'Забронировать',
  }
}


class BookingFormIndex extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      slots: [],
    }
  }

  async componentDidMount() {
    const stripePromise = loadStripe(stripe_key)
    this.stripe = await stripePromise
    this.fetchSessions()
  }

  fetchSessions = () => {
    axios
      .get(session_api_url)
      .then(ret => {
        this.setState({
          slots: ret.data
        })
      })
      .catch(error => {
        //console.log(`error fetching sessions: ${error}`)
        this.setState({ loading: false, error })
      })
  }

  getSlot = slot_id => {
    //console.log(this.state.slots)
    return this.state.slots && this.state.slots.filter(slot => slot.id === slot_id)[0]
  }

  render() {
    const { location, lang='en', bookOnly=false } = this.props
    const submit = bookOnly ? `book-online`: `checkout`
    const payButtonMessage = (currency, slot) => ({
      'eur': `${this.getSlot(slot).price_eur} €`,
      'usd': `$${this.getSlot(slot).price_usd}`,
      'gbp': `£${this.getSlot(slot).price_gbp}`,
      'ils': `₪${this.getSlot(slot).price_ils}`,
    }[currency])

    return (
      <>
      <Formik
        initialValues={{ email: '', phone: '', message: '', currency: 'gbp', timezone: DateTime.local().zoneName }}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post(`${api_url}/${submit}`, {
              slot_id: values.slot,
              email: values.email,
              phone: values.phone,
              name: values.name,
              message: values.message,
              currency: values.currency,
              timezone: values.timezone,
              lang: lang,
            })
            .then(ret => {
              //console.log('return from api:')
              //console.log(ret)
              //console.log(ret.data.id)
              if (bookOnly) {
                const redirectUrl = lang === 'ru' ? '/ru/thanks/' : '/thanks/'
                window.location.replace(redirectUrl)
              } else {
                this.stripe.redirectToCheckout({
                  sessionId: ret.data.id,
                }).then(function (result) {
                  // Display result.error.message to your customer
                  alert('There was a problem with checkout. Please retry or contact hello@duraj.space')
                })
              }
              setSubmitting(false)
            })
            .catch(error => {
              alert('Issue with checkout. Please contact hello@duraj.space')
              this.setState({ loading: false, error })
              setSubmitting(false)
            })
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().label("Name").min(3).required(),
          slot: Yup.string().required("A slot selection is required."),
          email: Yup.string().label("Email").email().required(),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            //dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            //handleReset,
          } = props;
          return (
            <form id="book" onSubmit={handleSubmit}>
            <Flex flexWrap='wrap'>
            <Box width={[1, 6/12]}>
              <Label htmlFor="name" style={{ display: 'block' }}>
                {label[lang].name}
              </Label>
              <Input
                id="name"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name ? 'text-input error' : 'text-input'
                }
              />
              {errors.name &&
                  touched.name && <div className="input-feedback">{errors.name}</div>}

              <Label htmlFor="email" style={{ display: 'block' }}>
                {label[lang].email}
              </Label>
              <Input
                id="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email ? 'text-input error' : 'text-input'
                }
              />
              {errors.email &&
                  touched.email && <div className="input-feedback">{errors.email}</div>}

                  <Label htmlFor="phone" style={{ display: 'block' }}>
                    {location === 'online' ? label[lang].skype : label[lang].phone}
                  </Label>
                  <Input
                    id="phone"
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.phone && touched.phone ? 'text-input error' : 'text-input'
                    }
                  />
                  {errors.phone &&
                      touched.phone && <div className="input-feedback">{errors.phone}</div>}

              <br />
            </Box>

              <Box width={[1, 6/12]}>
              <Label htmlFor="message" style={{ display: 'block' }}>
                {label[lang].message}
              </Label>
              <Textarea
                id="message"
                type="textarea"
                maxlength="500"
                rows="5"
                cols="35"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.message && touched.message ? 'text-input error' : 'text-input'
                }
              /><br />
            </Box>
          </Flex>

              {
                (lang === 'en' ?
                  <div>{`Choose a session date and time. (Displayed in your local timezone: ${DateTime.local().zoneName}):`}</div>
                  :
                  <div>{`Время показывается в вашем местном часовом поясе: ${DateTime.local().zoneName}`}</div>
                )
              }

              <BookingWidget slots={this.state.slots}
                updateValue={(slot_id) => setFieldValue('slot', slot_id)} />

              {
                (lang === 'en' ?
                  <div>{`Choose the currency you would like to pay in:`}</div>
                  :
                  <div>{`Choose the currency you would like to pay in:`}</div>
                )
              }

              <CurrencyButtonGroup
                updateValue={(currency) => setFieldValue('currency', currency)} />

              <Button type="submit" 
                disabled={isSubmitting || Object.keys(errors).length !== 0}>
                {isSubmitting && <FontAwesomeIcon icon="spinner" spin />}
                {
                  bookOnly ?
                  ` ${label[lang].book}`
                  :
                  ` ${label[lang].pay} ${values.slot ? payButtonMessage(values.currency, values.slot) : ''}`
                }
              </Button>
            </form>
          );
        }}
      </Formik>
      </>
    )
  }
}

export default BookingFormIndex
