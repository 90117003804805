import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Link } from 'gatsby'

import { Location } from '@reach/router'

import styled from 'styled-components'

import { Flex, Box } from '@rebass/grid'

export const Button = styled.a`
  //text-transform: uppercase;
  padding: .15rem .3rem .15rem;
  border: none;
  background-color: #fac129;
  color: #6b6a83;
  font-size: 80%;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
`

const Heading = styled.h1`
  margin-bottom: .5rem;
  color: #6b6a83;
`

const HeaderSubtitle = styled.div`
  text-decoration: none;
  h5 {
    margin-bottom:.5rem;
  }
`

const LanguageSelector = styled.a`
  color: rgb(59,162,220);
  font-size: 80%;
`

export default ({ slug, lang='en-gb'}) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              lang
              data {
                title {
                  text
                }
                subtitle {
                  text
                  html
                }
              }
            }
          }
        }
        allSitePage {
          edges {
            node {
              path
            }
          }
        }
      }
    `}
    render={data => {
      const headerData = data.allPrismicHeader.edges.filter(e => e.node.lang === lang)[0].node.data
      const allPages = data.allSitePage.edges.map(e => e.node.path.replace(/\/+$/, ""))
      return (
      <Flex alignItems='center' justifyContent='space-between'>
        <Box width={9/12} pl={1}>
          <HeaderSubtitle as={Link} to={lang === 'en-gb' ? '/' : '/ru'}>
            <Heading>{headerData.title.text}</Heading>
          <h5 style={{color:'#6b6a83'}}>{headerData.subtitle.text}
          <br />Mill Road, Cambridge UK
          <br />hello@duraj.space
        </h5>
        </HeaderSubtitle>
      </Box>
      <Box>
      <div>
        <a href='#book'>
          <Button>{lang === 'ru' ? 'Бронирование' : 'Appointments'}</Button>
        </a>
    </div>
    <div>
        <Location>
          {({ navigate, location }) => {
            // XXX this code is shit, ru is hardcoded should be a list of langs
            const slice = location.pathname.split('/').filter(e => e !== '' && e !== 'ru').slice(-1)
            const basename = slice.length > 0 ? slice[0] : ''
            const langs = [
              { code: 'en-gb', name: 'English', path: `/${basename}` },
              { code: 'ru',    name: 'Русский', path: `/ru/${basename}`},
            ].filter(l => l.code !== lang)
            return (
              langs.filter(l => allPages.includes(l.path.replace(/\/+$/, ""))).map(l =>
                <LanguageSelector key={`langSelector-${l.code}`} as={Link} to={l.path}>
                  {l.name}
                </LanguageSelector>
              )
          )}}
        </Location>
    </div>
  </Box>
      </Flex>
      )}}
  />
)
