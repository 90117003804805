import React, { Component } from 'react'

import 'react-day-picker/lib/style.css';

import styled from 'styled-components'

export const Button = styled.button`
  text-transform: uppercase;
  padding: .5rem 2rem .5rem;
  border: 2px solid #fac129;
  background-color: ${props => props.selected ? "#fac129" : "white"};
  color: #888;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
  margin: .5rem;
`

const SlotButton = styled(Button)`
  text-transform: none;
`


class CurrencyButtonGroup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: 'gbp',
    }
  }

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    this.setState({
      selected: undefined,
    })
    this.props.updateValue && this.props.updateValue('')
  }

  handleButtonClicked = (param) => (e) => {
    this.setState({
      selected: param
    })
    this.props.updateValue && this.props.updateValue(param)
  }


  render() {

    const currency_msg = (currency) => ({
      'gbp': '£ (GBP)',
      'eur': '€ (EUR)',
      'usd': '$ (USD)',
      'ils': '₪ (ILS)',
    }[currency])

    return (
      <div>
        {['gbp', 'eur', 'usd']
            .map(currency =>
              <SlotButton
                key={`bookingwidget_${currency}`}
                type='button'
                onClick={this.handleButtonClicked(currency)}
                selected={this.state.selected === currency}
              >
                <div>
                  {currency_msg(currency)}
                </div>
              </SlotButton>
        )}
      </div>
    )
  }
}

export default CurrencyButtonGroup